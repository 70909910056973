/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

body {
  /*  font-family: 'Roboto';*/
  font-family: 'Rajdhani SemiBold';
  /*  font-weight: 400;*/
  font-style: normal;
  font-size: 16px;
  overflow-x: hidden;
  background: #161c24;
  color: #90A3B7;
}