.ellipsis-container {
    display: flex;
    align-items: center;
  }
  
  .ellipsis-dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    /* background-color: #000; */
    background-image: linear-gradient(to right, #2A6BBC , #0DC4D6);
    margin-right: 5px;
    animation: ellipsis-animation 0.6s infinite;
  }
  
  .ellipsis-dot:nth-of-type(1) {
    animation-delay: 0.1s;
  }
  
  .ellipsis-dot:nth-of-type(2) {
    animation-delay: 0.2s;
  }
  
  @keyframes ellipsis-animation {
    0% {
      opacity: 0.2;
      transform: translateY(0);
    }
    20% {
      opacity: 1;
      transform: translateY(-5px);
    }
    40% {
      opacity: 0.2;
      transform: translateY(0);
    }
  }