.pagination-container {
    display: flex;
    list-style-type: none;
  }
  
  .pagination-container .pagination-item {
    /* padding: 0 12px; */
    height: 25px;
    text-align: center;
    margin: auto 4px;
    color: #90A3B7;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 14px;
    min-width: 25px;
    justify-content: center;
  }
  
  .pagination-container .pagination-item.dots:hover {
    background-color: transparent;
    cursor: default;
  }
  
  .pagination-container .pagination-item:hover {
    background-color: rgba(49, 76, 80, 0.16);
    cursor: pointer;
  }
  
  .pagination-container .pagination-item.selected {
    background-color: #1C242E;
    font-weight: 700;
    color: #ffffff;
  }
  
  .pagination-container .pagination-item .arrow::before {
    position: relative;
    /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
    content: '';
    /* By using an em scale, the arrows will size with the font */
    display: inline-block;
    width: 0.4em;
    height: 0.4em;
    border-right: 0.12em solid  rgb(255, 255, 255);;
    border-top: 0.12em solid  rgb(255, 255, 255);;
  }
  
  .pagination-container .pagination-item .arrow.left {
    transform: rotate(-135deg) translate(-50%);
  }
  
  .pagination-container .pagination-item .arrow.right {
    transform: rotate(45deg);
  }
  
  .pagination-container .pagination-item.disabled {
    pointer-events: none;
  }
  
  .pagination-container .pagination-item.disabled .arrow::before {
    border-right: 0.12em solid rgba(228, 212, 212, 0.43);
    border-top: 0.12em solid rgba(228, 212, 212, 0.43);
  }
  
  .pagination-container .pagination-item.disabled:hover {
    background-color: transparent;
    cursor: default;
  }
  